import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "submit" ]

  connect() {
    console.log("TEEEEEEEEEEEEEEEEEEEEEST")
  }

  addConfirmMessageToSubmit(event) {
    const select = event.currentTarget
    const country = event.currentTarget.dataset.country

    if (select.value != country) {
      this.submitTarget.dataset.confirm = select.dataset.text
    } else {
      this.submitTarget.removeAttribute('data-confirm')
    }
  }

  submitFormOnChange(event) {
    console.log('change')
    const select = event.currentTarget

    select.form.submit()
  }
}
